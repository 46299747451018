import React from 'react';
import {NavLink} from 'react-router-dom';
import { BsInstagram, BsFacebook, BsYoutube, BsFillTelephoneFill } from "react-icons/bs";
import {FaLocationDot} from 'react-icons/fa6';
import {GrMail} from 'react-icons/gr';

export default function Footer() {
    return (
        <div>
            <div className = "footerClass theFonts">
                <div className = "footMenu whiteColor">
                    <h3 className = "h3Head onlyCenter">Our Office</h3>
                    <div className = "onlyCenter phoneNumber">
                        <i className = "footerIcon"><BsFillTelephoneFill /></i>
                        <a className = "phoneNumber" href="tel:+18766127921" title="tel: +18766127921">(876) 612-7921</a> 
                    </div>
                    <div className = "onlyCenter phoneNumber">
                        <i className = "footerIcon"><GrMail /></i>
                        <a className = "phoneNumber" href="mailto:support@citidentalja.com" target="_blank" rel="noreferrer" title="mailto:support@citidentalja.com">
                            support@citidentalja.com
                        </a>
                    </div>
                    <div className = "onlyCenter phoneNumber">
                        <i className ='footerIcon'><FaLocationDot /></i>
                        <a className = "locationLink phoneNumber" href = "https://tinyurl.com/CitidentalPortmore" target="_blank" rel="noopener noreferrer">
                            Sovereign Village, Portmore
                        </a>
                    </div>
                    <div className = "onlyCenter phoneNumber">
                        <i className ='footerIcon'><FaLocationDot /></i>
                        <a className = "locationLink phoneNumber" href = "https://tinyurl.com/CitidentalLiguanea" target="_blank" rel="noopener noreferrer">
                            Progressive Plaza, Liguanea
                        </a>
                    </div>
                </div>
                
                <div className = "footMenu whiteColor">
                    <h3 className = "h3Head onlyCenter">Our Socials</h3>
                    <div className = "onlyCenter">
                        <a href="https://www.instagram.com/citidentalja/" className = "socialIcon"><BsInstagram /></a>
                        <a href="https://www.facebook.com/Citide/" className = "socialIcon"><BsFacebook /></a>
                        <a href="https://www.youtube.com/@citidentalltd9808" className = "socialIcon"><BsYoutube /></a>
                    </div>
                </div>

                <div className = "footMenu">
                    <h3 className = "h3Head onlyCenter">Pages</h3>
                    <div className = "footNav onlyCenter">
                        <NavLink className = "footLink" to = "/about">About CitiDental</NavLink>
                        <NavLink className = "footLink" to = "/our-team">Our Team</NavLink>
                        <NavLink className = "footLink" to = "/services">Services</NavLink>
                        <NavLink className = "footLink" to = "/smiles">Smile Gallery</NavLink>
                        <a className = "footLink" href = "https://tinyurl.com/bookCitidental">Book an Appointment</a>
                        <NavLink className = "footLink" to = "/contact">Contact Us</NavLink>
                    </div>
                </div>
            </div>
            <div className = "rightsSection onlyCenter">
                <p><span>&#169;</span> 2024 CitiDental & Implant Centre. All rights reserved</p>
            </div>
        </div>
    );
}
