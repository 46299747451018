import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function ImageSlider(props) {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000
    };
    return (
        <Slider {...settings}>
            {props.sources.map((source, i) => (
                <div key = {i} className = {props.divClass}>
                    <LazyLoadImage className = {props.imageClass} src = {source} alt = {props.alt[i]}/>
                </div>
            ))}
        </Slider>
    );
};
