import Sagicor from '../files/sagicor-logo.webp';
import Guardian from '../files/guardian-life-logo.webp';
import Canopy from '../files/canopy-logo.webp';
import AboutDrMoore from '../sections/aboutDrMoore.js'
import coverPhoto1792 from '../files/coverPhoto1792.webp';
import coverPhoto1180 from '../files/coverPhoto1180.webp';
import coverPhoto1280 from '../files/coverPhoto1280.webp';
import coverPhoto1366 from '../files/coverPhoto1366.webp';
import coverPhoto1440 from '../files/coverPhoto1440.webp';
import coverPhoto1536 from '../files/coverPhoto1536.webp';
import coverPhoto1600 from '../files/coverPhoto1600.webp';
import coverPhoto1920 from '../files/coverPhoto1920.webp';
import Reviews from '../sections/reviews.js';
import Promise from '../sections/promise.js';
import Services from '../sections/servicesReel.js';
import { LazyLoadImage } from "react-lazy-load-image-component";
import {FaLocationDot} from 'react-icons/fa6';
import frontpage414 from '../files/frontpage414.webp';
import frontpage360 from '../files/frontpage360.webp';
import frontpage390 from '../files/frontpage390.webp';
import frontpage601 from '../files/frontpage601.webp';
import frontpage768 from '../files/frontpage768.webp';
import frontpage800 from '../files/frontpage800.webp';
import frontpage810 from '../files/frontpage810.webp';
import frontpage820 from '../files/frontpage820.webp';
import {Helmet} from 'react-helmet';

export default function Homepage() {
  return (
    <div>
      <Helmet>
        <title>CitiDental & Implant Centre | Making Jamaica Smile Brighter</title>
        <meta
          name="description"
          content="A full-service dental office dedicated to making Jamaican smiles brighter. 
                  We perform implants, root canals, braces, and more. Built by Emmanuel Ebhohimen."
        />
      </Helmet>
      <div className = "fullScreenPic">
          <img
            className="fullPhoto mobDarken mobileOnly" 
            src={frontpage414} 
            srcSet={`${frontpage360} 360w, ${frontpage390} 390w, ${frontpage414} 414w, 
                    ${frontpage601} 601w, ${frontpage768} 768w, ${frontpage800} 800w, 
                    ${frontpage810} 810w, ${frontpage820} 820w`}
            sizes="(max-width: 360px) 360px, (max-width: 390px) 390px, (max-width: 414px) 414px,
                   (max-width: 601px) 601px, (max-width: 768px) 768px, (max-width: 800px) 800px,
                   (max-width: 810px) 810px, (max-width: 820px) 820px, 414px"
            alt="CitiDental's Bright Smiles"
            fetchpriority="high"
          />
          <img
            className="fullPhoto mobDarken desktopOnly" 
            src={coverPhoto1792} 
            srcSet={`${coverPhoto1180} 1180w, ${coverPhoto1280} 1280w, ${coverPhoto1366} 1366w, 
                    ${coverPhoto1440} 1440w, ${coverPhoto1536} 1536w, ${coverPhoto1600} 1600w, 
                    ${coverPhoto1792} 1792w, ${coverPhoto1920} 1920w`}
            sizes="(max-width: 1180px) 1180px, (max-width: 1280px) 1280px, (max-width: 1366px) 1366px,
                  (max-width: 1440px) 1440px, (max-width: 1536px) 1536px, (max-width: 1600px) 1600px,
                  (max-width: 1792px) 1792px, (max-width: 1920px) 1920px, 100vw"
            alt = "CitiDental's Bright Smiles"
            fetchpriority="high"
          />
          <p className = "mobileOnly mobTitle">Creating Radiant Smiles with Exemplary Service</p>
          <a className = "mobSched mobileOnly" href = "https://tinyurl.com/bookCitidental">Book an Appointment</a>
          <a className = "mobCall mobileOnly" href="tel:+18766127921" title="tel: +18766127921">Call Us</a>
          <a className = "mobileOnly mobLoc locationLink" href = "https://tinyurl.com/citidentalOffices" target="_blank" rel="noopener noreferrer">
            <FaLocationDot id = "mobIc"/> Visit Our Offices
          </a>
          <div className = "bottomCover onlyCenter">
            <p className = "embolden font20">
                <span>Visit us </span>
                <i className ='whiteColor' style = {{padding: 5 + 'px', fontSize: 19 + 'px'}}><FaLocationDot /></i>
                <a className = "locationLink" href = "https://tinyurl.com/CitidentalPortmore" target="_blank" rel="noopener noreferrer">
                    Sovereign Village, Portmore
                </a>
                <i className ='whiteColor' style = {{padding: 5 + 'px', fontSize: 19 + 'px'}}><FaLocationDot /></i>
                <a className = "locationLink" href = "https://tinyurl.com/CitidentalLiguanea" rel="noopener noreferrer" target="_blank">
                    Progressive Plaza, Liguanea
                </a>
            </p>
          </div>
      </div>
      <AboutDrMoore/>
      <Services 
        title = "Our Services"
        id = "mainService1" 
        id2 = "serviceGrid"
        h3 = "servHead"/>
      <Promise />
      <Reviews />
      <div id = "insurance">
        <h3 id = "insuranceHead">We Accept All Major Dental Insurance</h3>
        <div id = "insuranceProviders">
          <LazyLoadImage id = "sagicor" className = "zoom" src = {Sagicor} alt = "We accept Sagicor Insurance" />
          <LazyLoadImage id = "guardian" className = "zoom" src = {Guardian} alt = "We accept Guardian Life Insurance" />
          <LazyLoadImage className = "zoom" src = {Canopy} alt = "We accept Canopy Insurance" />
        </div>
      </div>
    </div>
  );
}
