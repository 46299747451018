import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { LazyLoadImage } from "react-lazy-load-image-component";
import {NavLink} from 'react-router-dom';

export default function ServicesSlider(props) {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        autoplay: true,
        autoplaySpeed: 5000
    };
    return (
        <Slider {...settings}>
            {props.images.map((image, i) => (
                <div key = {i} className = {props.divClass}>
                    <NavLink title = "Citidental's Dental Treatment Services" rel = "noopener nonreferrer" to = {props.link}>
                        <LazyLoadImage className = {props.picClass} src = {image} alt = {props.alt[i]} />
                    </NavLink>
                    <h3 className = {props.headClass}><span id = {props.headId}>{props.headText[i]}</span></h3>
                </div>
            ))}
        </Slider>
    );
};