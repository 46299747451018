import React from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import ligwalk1792 from '../files/ligwalk1792.webp';
import ligwalk1180 from '../files/ligwalk1180.webp';
import ligwalk1280 from '../files/ligwalk1280.webp';
import ligwalk1366 from '../files/ligwalk1366.webp';
import ligwalk1440 from '../files/ligwalk1440.webp';
import ligwalk1536 from '../files/ligwalk1536.webp';
import ligwalk1600 from '../files/ligwalk1600.webp';
import ligwalk1920 from '../files/ligwalk1920.webp';
import mobileAbout414 from '../files/mobileAbout414.webp';
import mobileAbout360 from '../files/mobileAbout360.webp';
import mobileAbout390 from '../files/mobileAbout390.webp';
import mobileAbout601 from '../files/mobileAbout601.webp';
import mobileAbout768 from '../files/mobileAbout768.webp';
import mobileAbout800 from '../files/mobileAbout800.webp';
import mobileAbout810 from '../files/mobileAbout810.webp';
import mobileAbout820 from '../files/mobileAbout820.webp';
import Kids from '../files/kids.webp';
import Cleaning from '../files/cleaning.webp';
import Consult from '../files/consult.webp';
import FrontDesk from '../files/frontdesk.webp';
import Halls from '../files/halls.webp';
import Lobby from '../files/lobby.webp';
import Operatory from '../files/operatory.webp';
import Receptionist from '../files/receptionist.webp';
import PortFront from '../files/portDesk.webp';
import Consultation from '../files/consultationRoom.webp';
import PortHalls from '../files/officeHalls.webp';
import PortOperatory from '../files/portOperatory.webp';
import Porto from '../files/portHalls.webp';
import Waiting from '../files/lounge.webp';
import Couch from '../files/couch.webp';
import Done from '../files/officeDone.webp';
import Slider from '../functions/ImageSlider.js';
import {Helmet} from 'react-helmet';

export default function About() {
    return (
        <div>
            <Helmet>
                <title>About CitiDental & Implant Centre | Our Story and Mission</title>
                <meta
                name="description"
                content="We are a Jamaican dental office dedicated to caring for patients with a smile and giving back to the community. Our goal is to treat every person at least once."
                />
            </Helmet>
            <div className = 'pictureDivText'>
                <img 
                    className = "darkenCover fullPhoto desktopOnly" 
                    src = {ligwalk1440}
                    srcSet={`${ligwalk1180} 1180w, ${ligwalk1280} 1280w, ${ligwalk1366} 1366w, 
                            ${ligwalk1440} 1440w, ${ligwalk1536} 1536w, ${ligwalk1600} 1600w, 
                            ${ligwalk1792} 1792w, ${ligwalk1920} 1920w`}
                    sizes="(max-width: 1180px) 1180px, (max-width: 1280px) 1280px, (max-width: 1366px) 1366px,
                        (max-width: 1440px) 1440px, (max-width: 1536px) 1536px, (max-width: 1600px) 1600px,
                        (max-width: 1792px) 1792px, (max-width: 1920px) 1920px, 100vw"
                    alt = "CitiDental's Doctors and Staff"
                    fetchpriority="high"
                />
                <img 
                    className = "darkenCover fullPhoto mobileOnly" 
                    src = {mobileAbout601}
                    srcSet={`${mobileAbout360} 360w, ${mobileAbout390} 390w, ${mobileAbout414} 414w, 
                            ${mobileAbout601} 601w, ${mobileAbout768} 768w, ${mobileAbout800} 800w, 
                            ${mobileAbout810} 810w, ${mobileAbout820} 820w`}
                    sizes="(max-width: 360px) 360px, (max-width: 390px) 390px, (max-width: 414px) 414px,
                        (max-width: 601px) 601px, (max-width: 768px) 768px, (max-width: 800px) 800px,
                        (max-width: 810px) 810px, (max-width: 820px) 820px, 414px"
                    alt = "CitiDental's Doctors and Staff"
                    fetchpriority="high"
                />
                <h1 className = 'pictureHeader'>About CitiDental</h1>
                <p className = 'pictureText'>"Changing one Smile at a Time"</p>
            </div>
            <div id = "paraDiv">
                <p className = "aboutPara">
                    Welcome to Citidental, your trusted dental practice since 2017. 
                    Our mission is to provide "Care with a Smile," delivering comprehensive 
                    and world-class dental care to all our patients. At Citidental, we 
                    prioritize excellent service, patient care, and pain-free dentistry to 
                    ensure a positive dental experience for everyone who walks through our doors.
                    <br className='mobileOnly'/><br className='mobileOnly'/><br className='mobileOnly'/>
                    <br className='mobileOnly'/>
                    What sets Citidental apart is our commitment to 
                    providing pain-free dentistry in a clean and safe environment. Our 
                    state-of-the-art dental practice is equipped with the latest equipment and 
                    advanced techniques, allowing us to deliver the highest quality of care.
                </p>
                <br className='mobileOnly'/><br className='mobileOnly'/><br className='mobileOnly'/>
                <br className='mobileOnly'/>
                <p className = "aboutPara">
                    We believe in fair pricing and offer flexible financial 
                    options to make dental care accessible to all. Our range of dental services is 
                    designed to meet all your oral health needs. From implants, crowns and bridges, 
                    and cosmetic dentistry to braces, extractions and fillings, teeth whitening, 
                    root canals, oral surgery, and dentures, our experienced team of dentists is 
                    skilled in delivering personalized treatments to achieve optimal results.
                    <br className='mobileOnly'/><br className='mobileOnly'/><br className='mobileOnly'/>
                    <br className='mobileOnly'/>
                    When you visit Citidental, you can trust that you are in expert hands. 
                    Our head dentist, Dr. Moore, is a recognized leader in the field and serves 
                    on the dental board of Jamaica. Our team of dentists and staff are dedicated to 
                    providing exceptional care and ensuring your comfort throughout every procedure.
                </p>
                <br className='mobileOnly'/><br className='mobileOnly'/><br className='mobileOnly'/>
                <br className='mobileOnly'/>
                <p className = "aboutPara">
                    We prioritize patient comfort and offer various amenities, including music, 
                    television, sunglasses for light sensitivity, water, snacks, and air conditioning 
                    during your visit. Each procedure is performed with precision and care, ensuring 
                    your well-being and satisfaction. At Citidental, we believe in giving back to the 
                    community.
                    <br className='mobileOnly'/><br className='mobileOnly'/><br className='mobileOnly'/>
                    <br className='mobileOnly'/>
                    We have sponsored sports day events for schools across Jamaica and donated 
                    funds for COVID relief to Meadowbrook High School. We strive to make a positive impact 
                    and help those in need whenever possible. We understand the importance of convenience, 
                    and you can schedule your appointments easily through our website, by phone, or by 
                    walking into our office. 
                </p>
                <br className='mobileOnly'/><br className='mobileOnly'/><br className='mobileOnly'/>
                <br className='mobileOnly'/>
                <p className = "aboutPara">
                    Our friendly and accommodating staff are here to assist you at 
                    every step. We take pride in our numerous satisfied patients who have left us over 
                    150 5-star reviews, highlighting their trust and satisfaction with our care. 
                    We not only provide dental treatments but also educate our patients on practicing good 
                    oral hygiene to maintain optimal oral health.
                    <br className='mobileOnly'/><br className='mobileOnly'/><br className='mobileOnly'/>
                    <br className='mobileOnly'/>
                    Thank you for choosing Citidental 
                    as your dental care provider. We look forward to serving you with a smile and 
                    providing the highest standard of dental care to help you achieve a healthy and 
                    beautiful smile.
                </p>
            </div>
            <div id = "aroundOffice">
                <h2 className = "onlyCenter mobIn">In and Around Our Offices</h2>
                <h3 className = "officeName">Our Liguanea Office</h3>
                <div className = "mobileOnly">
                    <Slider 
                        sources = {[Kids, Lobby, Consult, Operatory, Receptionist, Halls, FrontDesk, Cleaning]}
                        alt = {[`CitiDental Kids Play Area`, "CitiDental Waiting Area", "CitiDental Consultation Room", 
                                "CitiDental Operatories", "CitiDental Receptionist", "CitiDental Walkways", 
                                "CitiDental Front Desk", "CitiDental Sterilization Room"]}
                        imageClass = "aboutPic"
                    />
                </div>
                <div className = "aboutGrid desktopOnly">
                    <div className = "aboutPicGrid zoom">
                        <LazyLoadImage 
                            className = "aboutPic" 
                            src = {Kids} 
                            alt = "CitiDental Kids Play Area" 
                        />
                    </div>
                    <div className = "aboutPicGrid zoom">
                        <LazyLoadImage 
                            className = "aboutPic" 
                            src = {Lobby} 
                            alt = "CitiDental Waiting Area" 
                        />
                    </div>
                    <div className = "aboutPicGrid zoom">
                        <LazyLoadImage 
                            className = "aboutPic" 
                            src = {Consult} 
                            alt = "CitiDental Consultation Room" 
                        />
                    </div>
                    <div className = "aboutPicGrid zoom">
                        <LazyLoadImage 
                            className = "aboutPic" 
                            src = {Operatory} 
                            alt = "CitiDental Operatories" 
                        />
                    </div>
                    <div className = "aboutPicGrid zoom">
                        <LazyLoadImage 
                            className = "aboutPic" 
                            src = {Receptionist} 
                            alt = "CitiDental Receptionist" 
                        />
                    </div>
                    <div className = "aboutPicGrid zoom">
                        <LazyLoadImage 
                            className = "aboutPic" 
                            src = {Halls} 
                            alt = "CitiDental Walkways" 
                        />
                    </div>
                    <div className = "aboutPicGrid zoom">
                        <LazyLoadImage 
                            className = "aboutPic" 
                            src = {FrontDesk} 
                            alt = "CitiDental Front Desk" 
                        />
                    </div>
                    <div className = "aboutPicGrid zoom">
                        <LazyLoadImage 
                            className = "aboutPic" 
                            src = {Cleaning} 
                            alt = "CitiDental Sterilization Room" 
                        />
                    </div>
                </div>
                <h3 id = "portOfficeName" className = "officeName">Our Portmore Office</h3>
                <div className = "mobileOnly">
                    <Slider 
                        sources = {[PortFront, Consultation, PortHalls, PortOperatory, Porto, Waiting, Couch, Done]}
                        alt = {[`CitiDental Portmore Front Desk`, "CitiDental Portmore Hallways", "CitiDental Portmore Consultation Room", 
                                "CitiDental Portmore Operatories", "CitiDental Portmore Office", "CitiDental Portmore Waiting Area", 
                                "CitiDental Portmore Sitting Area", "CitiDental Portmore Office"]}
                        imageClass = "aboutPic"
                    />
                </div>
                <div class = "aboutGrid desktopOnly">
                    <div className = "aboutPicGrid zoom">
                        <LazyLoadImage 
                            className = "aboutPic" 
                            src = {PortFront} 
                            alt = "CitiDental Portmore Front Desk" 
                        />
                    </div>
                    <div className = "aboutPicGrid zoom">
                        <LazyLoadImage 
                            className = "aboutPic" 
                            src = {Consultation} 
                            alt = "CitiDental Portmore Hallways" 
                        />
                    </div>
                    <div className = "aboutPicGrid zoom">
                        <LazyLoadImage 
                            className = "aboutPic" 
                            src = {PortHalls} 
                            alt = "CitiDental Portmore Consultation Room" 
                        />
                    </div>
                    <div className = "aboutPicGrid zoom">
                        <LazyLoadImage 
                            className = "aboutPic" 
                            src = {PortOperatory} 
                            alt = "CitiDental Portmore Operatories" 
                        />
                    </div>
                    <div className = "aboutPicGrid zoom">
                        <LazyLoadImage 
                            className = "aboutPic" 
                            src = {Porto} 
                            alt = "CitiDental Portmore Office" 
                        />
                    </div>
                    <div className = "aboutPicGrid zoom">
                        <LazyLoadImage 
                            className = "aboutPic" 
                            src = {Waiting} 
                            alt = "CitiDental Portmore Waiting Area" 
                        />
                    </div>
                    <div className = "aboutPicGrid zoom">
                        <LazyLoadImage 
                            className = "aboutPic" 
                            src = {Couch} 
                            alt = "CitiDental Portmore Sitting Area" 
                        />
                    </div>
                    <div className = "aboutPicGrid zoom">
                        <LazyLoadImage 
                            className = "aboutPic" 
                            src = {Done} 
                            alt = "CitiDental Portmore Office" 
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}