import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import { IoIosMenu } from "react-icons/io";
import MobileMenu from '../functions/MobileMenu.js';
import { useState } from 'react';
import logo360 from '../files/logo360.webp';
import logo390 from '../files/logo390.webp';
import logo414 from '../files/logo414.webp';
import logo601 from '../files/logo601.webp';
import logo768 from '../files/logo768.webp';
import logo800 from '../files/logo800.webp';
import logo810 from '../files/logo810.webp';
import logo820 from '../files/logo820.webp';
import logo from '../files/logo.webp';

export default function Header() {
    const [mobileMenu, setMobileMenu] = useState(false);
    if (mobileMenu === true) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'unset';
    }
    return (
        <div className = {(mobileMenu) ? "headerBar slideUp" : "headerBar slideBefore"}>
            <div id = "divLogo">
                <Link to = "" title = "Citidental & Implant Centre">
                    <img 
                        id = "pageLogo" 
                        src = {logo}
                        srcSet={`${logo360} 360w, ${logo390} 390w, ${logo414} 414w, 
                                    ${logo601} 601w, ${logo768} 768w, ${logo800} 800w, 
                                    ${logo810} 810w, ${logo820} 820w, ${logo} 1180w`}
                        sizes="(max-width: 360px) 360px, (max-width: 390px) 390px, (max-width: 414px) 414px,
                                (max-width: 601px) 601px, (max-width: 768px) 768px, (max-width: 800px) 800px,
                                (max-width: 810px) 810px, (max-width: 820px) 820px, 300px" 
                        alt = "CitiDental & Implant Centre Logo"
                    />
                </Link>
                <IoIosMenu id = "menuButton" onClick = {() => {
                    setMobileMenu(prev => !prev);
                }}/>
                <MobileMenu
                    trigger = {mobileMenu}
                    setTrigger = {setMobileMenu}
                />
            </div>
            <nav id = "navBarId">
                <NavLink className = {({isActive}) => isActive ? "active-link headMenu" : "headMenu"} to = "/about">About</NavLink>
                <NavLink className = {({isActive}) => isActive ? "active-link headMenu" : "headMenu"} to = "/services">Services</NavLink>
                <NavLink className = {({isActive}) => isActive ? "active-link headMenu" : "headMenu"} to = "/our-team">Our Team</NavLink>
                <NavLink className = {({isActive}) => isActive ? "active-link headMenu" : "headMenu"} to = "/smiles">Smile Gallery</NavLink>
                <NavLink className = {({isActive}) => isActive ? "active-link headMenu" : "headMenu"} to = "/contact">Contact Us</NavLink>
                <a className = "bookNow" href = "https://tinyurl.com/bookCitidental">Book Now</a>
            </nav>
        </div>
    );
}