import { IoMdClose } from "react-icons/io";
import {NavLink} from 'react-router-dom';
import { BsInstagram, BsFacebook, BsYoutube, BsFillTelephoneFill } from "react-icons/bs";
import {GrMail} from 'react-icons/gr';

export default function MobileMenu(props) {
    return (props.trigger) ? (
        <div className = "mobileMenu">
            <div>
                <IoMdClose id = "menuClose" onClick = {() => props.setTrigger(false)} />
                <nav id = "mobNav">
                    <NavLink className = "mobileItem" onClick = {() => props.setTrigger(false)} to = "/">Home</NavLink>
                    <NavLink className = "mobileItem" onClick = {() => props.setTrigger(false)} to = "/about">About</NavLink>
                    <NavLink className = "mobileItem" onClick = {() => props.setTrigger(false)} to = "/services">Services</NavLink>
                    <NavLink className = "mobileItem" onClick = {() => props.setTrigger(false)} to = "/our-team">Our Team</NavLink>
                    <NavLink className = "mobileItem" onClick = {() => props.setTrigger(false)} to = "/smiles">Smile Gallery</NavLink>
                    <NavLink className = "mobileItem" onClick = {() => props.setTrigger(false)} to = "/contact">Contact Us</NavLink>
                    <a className = "mobileItem" href = "https://rb.gy/jx2bl">Book Now</a>
                </nav>
                <div id = "socialMobDiv">
                    <a href="https://www.instagram.com/citidentalja/" className = "mobSocialIcon" target="_blank" rel="noreferrer"><BsInstagram /></a>
                    <a href="https://www.facebook.com/Citide/" className = "mobSocialIcon" target="_blank" rel="noreferrer"><BsFacebook /></a>
                    <a href="https://www.youtube.com/@citidentalltd9808" className = "mobSocialIcon" target="_blank" rel="noreferrer"><BsYoutube /></a>
                    <a className = "mobSocialIcon" href="mailto:support@citidentalja.com" target="_blank" rel="noreferrer" title="mailto:support@citidentalja.com">
                        <GrMail />
                    </a>
                    <a className = "mobSocialIcon" href="tel:+18766127921" title="tel: +18766127921"><BsFillTelephoneFill /></a>
                </div>
            </div>
        </div>
    ) : "";
}
