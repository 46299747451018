import React from 'react';
import {AiFillStar} from 'react-icons/ai';
import SliderSimple from '../functions/SliderSimple.js';

export default function Reviews() {
    return (
        <div className = "is-Flex-Row">
            <div id = "reviewTitle">
                <h2 id = "reviewH2">Spotlight on Excellence: Top-rated Reviews</h2>
                <div id = "revStars">
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                </div>
                <a className = "embolden" href = "https://tinyurl.com/CitiDentalReviews" target="_blank" rel="noopener noreferrer" id = "revGoogle">
                    Over 150 5-Star Reviews!
                </a>
            </div>
            <div id = "reviews">
                <div className = "mobileOnly">
                    <SliderSimple
                        packages = {[`Punctuality is a 10/10. They opened 9am and my appointment was 9am..
                                Dentist was calm and is good at her job!!! The environment is very 
                                relaxing too.. surely recommend!! "Heart of love!"`, 
                                
                                `The Dentist was very gentle and she protected me along the way. 
                                She made sure to tell me everything she is doing before she does 
                                them and gave me feedback after my procedure. She was very warm!!!! 
                                The lady at the front desk was also very interactive and kind.`,
                                
                                `The dentist was very gentle and even provided music and a stress 
                                ball to ensure I was comfortable throughout the procedure. World class 
                                service was provided`,
                                
                                `CitiDental is the place to go for all your dental needs. Great 
                                customer service, patient and caring staff and of course high levels 
                                of professionalism. I had an awesome experience.`,
                                
                                `My experience at Citidental was excellent. The customer service 
                                I was given was superb. Thank you once again for your wonderful treatment`,
                                
                                `Service was great, each step was explained, questions were answered clearly 
                                and respectfully. I would go back every week. No lies`]}

                        names = {["Cardel Anderson", "Keisha Cameron", "MarvinADSummer", "Karleen Fuller",
                                "Fabian Fedderal", "Jamaica News Hub"]}
                        textClass = "black paddingBottom onlyCenter"
                        authClass = "black paddingTop onlyCenter"
                        divClass = "reviewSliderDiv"
                        over = "overarchingDiv"
                    />
                </div>
                <div id = "revBox1" className = "reviewBox">
                    <p className = "black paddingBottom">
                        As always, the staff is on another level with the care they display. 
                        Apart from being skilled at what they do, the experience is made by the consistent 
                        efforts to ensure your comfort. From TVs, to checking if you're positioned well, 
                        to checking if the temperature is OK, to taking the time to let you know exactly 
                        what is happenin, etc. I really don't see myself using another dentist's office anytime soon.
                    </p>
                    <hr />
                    <p className = "black onlyCenter paddingTop">
                        Michael Brown
                    </p>
                </div>
                <div id = "revBox2" className = "reviewBox">
                    <p className = "black paddingBottom">
                        I only have high praise for my experience getting a cleaning done. 
                        Appointment was easily set, friendly staff and first class treatment on arrival. 
                        Personalized greeting and music while I had my procedure done...I was so comfortable 
                        I almost didn't want to leave! Definitely recommending to everyone I know.
                    </p>
                    <hr />
                    <p className = "black onlyCenter paddingTop">
                        Christophe James
                    </p>
                </div>
                <div id = "revBox3" className = "reviewBox">
                    <p className = "black paddingBottom">
                        I love CitiDental! You're always a little nervous going to the dentist but the 
                        customer service is excellent and they really take care of you from the moment 
                        you walk through the door till when you leave. The work I had done today was 
                        excellent, and done with such care and the professional staff were skilled, 
                        very knowledgeable and ready to answer any questions you have to ask. I never thought 
                        I'd look forward to going to see the dentist until CitiDental. Thank you!
                    </p>
                    <hr />
                    <p className = "black onlyCenter paddingTop">
                        Tricia Williamson
                    </p>
                </div>
                <div id = "revBox4" className = "reviewBox">
                    <p className = "black paddingBottom">
                        I don't usually do online reviews BUT.....I love the customer service here. 
                        Doctors are very professional and  patient. I asked a lot of questions and they 
                        took the time to explain everything I asked without seeming annoyed. I love  them. 
                        I got treated at both the Portmore and the Liguanea branches and I got the same 
                        quality service from both. Dr. Denise Moore is the best ❤️❤️❤️❤️❤️. I highly recommend 
                        them especially for persons who fear going to the dentist.
                    </p>
                    <hr />
                    <p className = "black onlyCenter paddingTop">
                        Elizabeth Calvert
                    </p>
                </div>
            </div>
        </div>
    );
}