import React from 'react';
import {NavLink} from 'react-router-dom';
import moore360 from '../files/drmoore360.webp';
import moore390 from '../files/drmoore390.webp';
import moore414 from '../files/drmoore414.webp';
import moore601 from '../files/drmoore601.webp';
import moore768 from '../files/drmoore768.webp';
import moore800 from '../files/drmoore800.webp';
import moore810 from '../files/drmoore810.webp';
import moore820 from '../files/drmoore820.webp';
import moore1180 from '../files/drmoore1180.webp';
import moore1280 from '../files/drmoore1280.webp';
import moore1366 from '../files/drmoore1366.webp';
import moore1440 from '../files/drmoore1440.webp';
import moore1536 from '../files/drmoore1536.webp';
import moore1600 from '../files/drmoore1600.webp';
import moore1792 from '../files/drmoore1792.webp';
import moore1920 from '../files/drmoore1920.webp';
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function AboutDrMoore() {
    return (
        <div className = "mooreContainer">
            <div style = {{paddingTop: 35 + 'px'}} className = "mooreData">
                <LazyLoadImage 
                    className = "moorePort" 
                    src = {moore1600} 
                    srcSet={`${moore360} 360w, ${moore390} 390w, ${moore414} 414w, 
                            ${moore601} 601w, ${moore768} 768w, ${moore800} 800w, 
                            ${moore810} 810w, ${moore820} 820w, ${moore1180} 1180w, 
                            ${moore1280} 1280w, ${moore1366} 1366w, 
                            ${moore1440} 1440w, ${moore1536} 1536w, ${moore1600} 1600w, 
                            ${moore1792} 1792w, ${moore1920} 1920w`}
                    sizes="(max-width: 360px) 360px, (max-width: 390px) 390px, (max-width: 414px) 414px,
                            (max-width: 601px) 601px, (max-width: 768px) 768px, (max-width: 800px) 800px,
                            (max-width: 810px) 810px, (max-width: 820px) 820px, (max-width: 1180px) 1180px, 
                            (max-width: 1280px) 1280px, (max-width: 1366px) 1366px,
                            (max-width: 1440px) 1440px, (max-width: 1536px) 1536px, (max-width: 1600px) 1600px,
                            (max-width: 1792px) 1792px, 1920px, "
                    alt = "Meet Dr. Moore Portrait" 
                />
            </div>
            <div className = "mooreData">
                <h2 id = "meetFont">Meet Dr. Moore</h2>
                <p className = "mooreText">
                    Dr. Denise C. E. Moore, D.D.S, is a Jamaican dentist, trained at Columbia University, New York, USA, with a passion for dentistry that spans over 30 years. 
                    After studying and practicing all over the United States, she opened the first Citidental & Implant Centre location
                    in Portmore and the second in Liguanea. This state-of-the-art dental practice offers comprehensive services in 
                    general, cosmetic, restorative, and surgical dentistry. Dr. Denise Moore believes in educating patients about the 
                    importance of oral health and its connection to overall well-being.<br/><br/>
                </p>
                <p className = "mooreText" id = "secondPara">
                    With her expert team of dentists,
                    hygienists, and dental assistants, she is dedicated to helping individuals and families achieve optimal oral health 
                    and beautiful smiles. Driven by her commitment to excellence, Dr. Moore stays at the forefront of dental 
                    advancements and is a member of prestigious professional organizations. Citidental stands as an innovative & exceptional 
                    dental practice in Jamaica, providing excellent care, transforming lives with cutting-edge treatments, and having
                    thousands of satisfied patients in a clean & inviting environment.
                </p>
                <div className = "mooreLinkButton">
                    <NavLink className = "mooreOffice" to = "/about">Our Office</NavLink>
                    <a className = "mooreOffice" href = "https://tinyurl.com/bookCitidental">Book an Appointment</a>
                </div>
            </div>
        </div>
    );
}
