import React from 'react';
import lig360 from '../files/lig360.webp';
import lig390 from '../files/lig390.webp';
import lig414 from '../files/lig414.webp';
import lig601 from '../files/lig601.webp';
import lig768 from '../files/lig768.webp';
import lig800 from '../files/lig800.webp';
import lig810 from '../files/lig810.webp';
import lig820 from '../files/lig820.webp';
import lig1180 from '../files/lig1180.webp';
import lig1280 from '../files/lig1280.webp';
import lig1366 from '../files/lig1366.webp';
import lig1440 from '../files/lig1440.webp';
import lig1536 from '../files/lig1536.webp';
import lig1600 from '../files/lig1600.webp';
import lig1792 from '../files/lig1792.webp';
import lig1920 from '../files/lig1920.webp';
import {FaTooth} from 'react-icons/fa';
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Promise() {
    return(
        <div id = "promiseCont">
            <div id = "promiseBox">
                <div id = "promInfo">
                    <h2 id = "promHead">"Care with a Smile"</h2>
                    <div id = "promMobile" style = {{paddingBottom: 25 + 'px'}}>
                        <LazyLoadImage 
                            className = "fullWidth lighten" 
                            src = {lig390}
                            srcSet={`${lig360} 360w, ${lig390} 390w, ${lig414} 414w, 
                                    ${lig601} 601w, ${lig768} 768w, ${lig800} 800w, 
                                    ${lig810} 810w, ${lig820} 820w`}
                            sizes="(max-width: 360px) 360px, (max-width: 390px) 390px, (max-width: 414px) 414px,
                                (max-width: 601px) 601px, (max-width: 768px) 768px, (max-width: 800px) 800px,
                                (max-width: 810px) 810px, (max-width: 820px) 820px, 390px"
                            alt='Citidental & Implant Centre Promise of Care with a Smile'
                        />
                    </div>
                    <p id = "promText">
                        At CitiDental & Implant Centre, we prioritize your dental care and comfort. 
                        Here's what sets us apart:
                        <ul className = "promiseList">
                            <div className = "toothList">
                                <FaTooth /><li>Personalized care: Our compassionate team focuses on your individual needs.</li>
                            </div>
                            <div className = "toothList">
                                <FaTooth /><li>Thoughtful amenities: Enjoy complimentary refreshments, music, and TV entertainment.</li>
                            </div>
                            <div className = "toothList">
                                <FaTooth /><li>Skilled professionals: We stay up-to-date to provide the best treatment.</li>
                            </div>
                            <div className = "toothList">
                                <FaTooth /><li>Tailored approach: Customized plans address your unique dental needs.</li>
                            </div>
                            <div className = "toothList">
                                <FaTooth /><li>Service with a smile: Our friendly staff ensures a positive experience.</li>
                            </div>
                        </ul>
                        At CitiDental, we deliver exceptional dental care in a welcoming environment, 
                        putting your oral health and satisfaction first.
                    </p>
                </div>
                <div id = "promPic">
                    <LazyLoadImage 
                        className = "fullWidth lighten" 
                        src = {lig1792}
                        srcSet={`${lig1180} 1180w, ${lig1280} 1280w, ${lig1366} 1366w, 
                                ${lig1440} 1440w, ${lig1536} 1536w, ${lig1600} 1600w, 
                                ${lig1792} 1792w, ${lig1920} 1920w`}
                        sizes="(max-width: 1180px) 1180px, (max-width: 1280px) 1280px, (max-width: 1366px) 1366px,
                            (max-width: 1440px) 1440px, (max-width: 1536px) 1536px, (max-width: 1600px) 1600px,
                            (max-width: 1792px) 1792px, (max-width: 1920px) 1920px, 1792px"
                        alt='Citidental & Implant Centre Promise of Care with a Smile'
                    />
                </div>
            </div>
        </div>
    );
}