import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Homepage from './pages/home.js';
import About from './pages/about.js';
import Contact from './pages/contact.js';
import Team from './pages/team.js';
import Layout from './sections/layout.js'
import Smiles from './pages/smiles.js';
import Services from './pages/services.js';
import ScrollToTop from './functions/ScrollToTop.js';
import Careers from './pages/careers.js';

export default function App() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path = "" exact element = {<Layout />}>
					<Route index element = {<Homepage />}/>
					<Route path = "about" element = {<About />}/>
					<Route path = "our-team" element = {<Team />}/>
					<Route path = "contact" element = {<Contact />}/>
					<Route path = "smiles" element = {<Smiles />}/>
					<Route path = "services" element = {<Services />}/>
					<Route path = "careers" element = {<Careers />}/>
				</Route>
			</Routes>
		</BrowserRouter>
	);
}