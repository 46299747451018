import React from 'react';
import { BsInstagram, BsFacebook, BsYoutube, BsFillTelephoneFill } from "react-icons/bs";
import {GrMail} from 'react-icons/gr';

export default function Announcement() {
    return (
        <div id = "Announcement">
            <div id = "annDivLeft">
                <p>
                    <i className = "" style = {{marginRight: 10 + 'px', fontSize: 15 + 'px'}}><BsFillTelephoneFill /></i>
                    <span>Call Now </span>
                    <a className = "phone" href="tel:+18766127921" title="tel: +18766127921">+(876) 612-7921</a> 
                </p>
            </div>
            <div>
                <p id = "annCenter">"Care with a Smile"</p>
            </div>
            <div id = "annDivRight">
                <a href="https://www.instagram.com/citidentalja/" className = "socialIcon" target="_blank" rel="noreferrer"><BsInstagram /></a>
                <a href="https://www.facebook.com/Citide/" className = "socialIcon" target="_blank" rel="noreferrer"><BsFacebook /></a>
                <a href="https://www.youtube.com/@citidentalltd9808" className = "socialIcon" target="_blank" rel="noreferrer"><BsYoutube /></a>
                <a className = "socialIcon" href="mailto:support@citidentalja.com" target="_blank" rel="noreferrer" title="mailto:support@citidentalja.com">
                    <GrMail />
                </a>
            </div>
        </div>
    );
}
