import React from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import lineup1180 from '../files/lineup1180.webp';
import lineup1280 from '../files/lineup1280.webp';
import lineup1366 from '../files/lineup1366.webp';
import lineup1440 from '../files/lineup1440.webp';
import lineup1536 from '../files/lineup1566.webp';
import lineup1600 from '../files/lineup1600.webp';
import lineup1792 from '../files/lineup1792.webp';
import lineup1920 from '../files/lineup1920.webp';
// import Maicoo from '../files/Dr Maicoo.JPG';
import mobileLineup414 from '../files/mobileLineup414.webp';
import mobileLineup360 from '../files/mobileLineup360.webp';
import mobileLineup390 from '../files/mobileLineup390.webp';
import mobileLineup601 from '../files/mobileLineup601.webp';
import mobileLineup768 from '../files/mobileLineup768.webp';
import mobileLineup800 from '../files/mobileLineup800.webp';
import mobileLineup810 from '../files/mobileLineup810.webp';
import mobileLineup820 from '../files/mobileLineup820.webp';
import portpic360 from '../files/portpic360.webp';
import portpic390 from '../files/portpic390.webp';
import portpic414 from '../files/portpic414.webp';
import portpic601 from '../files/portpic601.webp';
import portpic768 from '../files/portpic768.webp';
import portpic800 from '../files/portpic800.webp';
import portpic810 from '../files/portpic810.webp';
import portpic820 from '../files/portpic820.webp';
import portpic1180 from '../files/portpic1180.webp';
import portpic1280 from '../files/portpic1280.webp';
import portpic1366 from '../files/portpic1366.webp';
import portpic1440 from '../files/portpic1440.webp';
import portpic1536 from '../files/portpic1536.webp';
import portpic1600 from '../files/portpic1600.webp';
import portpic1792 from '../files/portpic1792.webp';
import portpic1920 from '../files/portpic1920.webp';
import {Helmet} from 'react-helmet';

export default function About() {
    return (
        <div>
            <Helmet>
                <title>Our Dental Team | CitiDental & Implant Centre</title>
                <meta
                name="description"
                content="The Citidental Team consists of experienced dentists and hygienists who have been trained to prioritize treatment without sacrificing patient care."
                />
            </Helmet>
            <div className = 'pictureDivText'>
                <img 
                    className = "darkenCover fullPhoto desktopOnly" 
                    src = {lineup1792}
                    srcSet={`${lineup1180} 1180w, ${lineup1280} 1280w, ${lineup1366} 1366w, 
                            ${lineup1440} 1440w, ${lineup1536} 1536w, ${lineup1600} 1600w, 
                            ${lineup1792} 1792w, ${lineup1920} 1920w`}
                    sizes="(max-width: 1180px) 1180px, (max-width: 1280px) 1280px, (max-width: 1366px) 1366px,
                            (max-width: 1440px) 1440px, (max-width: 1536px) 1536px, (max-width: 1600px) 1600px,
                            (max-width: 1792px) 1792px, (max-width: 1920px) 1920px, 100vw"
                    alt = "CitiDental's Doctors and Staff"
                    fetchpriority="high"
                />
                <img 
                    className = "darkenCover fullPhoto mobileOnly" 
                    src = {mobileLineup601}
                    srcSet={`${mobileLineup360} 360w, ${mobileLineup390} 390w, ${mobileLineup414} 414w, 
                            ${mobileLineup601} 601w, ${mobileLineup768} 768w, ${mobileLineup800} 800w, 
                            ${mobileLineup810} 810w, ${mobileLineup820} 820w`}
                    sizes="(max-width: 360px) 360px, (max-width: 390px) 390px, (max-width: 414px) 414px,
                            (max-width: 601px) 601px, (max-width: 768px) 768px, (max-width: 800px) 800px,
                            (max-width: 810px) 810px, (max-width: 820px) 820px, 414px"
                    alt = "CitiDental's Doctors and Staff"
                    fetchpriority="high"
                />
                <h1 className = 'pictureHeader'>Our Team</h1>
            </div>
            <div class = "teamInfo">
                <div className = "teamDiv">
                    <div className = "teamPlayer">
                        <LazyLoadImage 
                            className = "teamPic" 
                            src = {portpic601}
                            srcSet={`${portpic360} 360w, ${portpic390} 390w, ${portpic414} 414w, 
                                    ${portpic601} 601w, ${portpic768} 768w, ${portpic800} 800w, 
                                    ${portpic810} 810w, ${portpic820} 820w, ${portpic1180} 1180w, 
                                    ${portpic1280} 1280w, ${portpic1366} 1366w, 
                                    ${portpic1440} 1440w, ${portpic1536} 1536w, ${portpic1600} 1600w, 
                                    ${portpic1792} 1792w, ${portpic1920} 1920w`}
                            sizes="(max-width: 360px) 360px, (max-width: 390px) 390px, (max-width: 414px) 414px,
                                    (max-width: 601px) 601px, (max-width: 768px) 768px, (max-width: 800px) 800px,
                                    (max-width: 810px) 810px, (max-width: 820px) 820px, (max-width: 1180px) 1180px,
                                    (max-width: 1280px) 1280px, (max-width: 1366px) 1366px,
                                    (max-width: 1440px) 1440px, (max-width: 1536px) 1536px, (max-width: 1600px) 1600px,
                                    (max-width: 1792px) 1792px, (max-width: 1920px) 1920px, 100vw" 
                            alt = "Dr. Moore in CitiDental Portmore Office"
                        />
                        <p className = "teamLabel">Dr. Denise C.E. Moore, D.D.S.</p>
                    </div>
                    <p className = "teamPara">
                        Dr. Moore, is a  notable dentist who is respected and serves as a member of Jamaica's Dental 
                        Council. With over three decades of unwavering dedication, she's established herself as a  committed Profesional of 
                        the dental industry, known as being passionate about patient care and dental treatments.
                        Graduating from the prestigious Columbia University, of New York, Dr. Moore embarked on a remarkable journey. Her first 
                        practice served as the cornerstone for her visionary venture, CitiDental.
                        <br/><br/><br/>
                        Dr. Moore's relentless pursuit of excellence has contributed to the dental landscape, facilitating dental care to the undeserved and disabled community. Dr. Moore's 
                        legacy goes beyond accolades. 
                        Her compassionate approach and commitment to elevating dental healthcare is evident by her involvement in outreach & community.Her unwavering dedication to providing great & exceptional care, coupled with her innovative 
                        contributions, define Dr. Moore's legacy, reaffirming her position as a leader in dentistry.
                    </p>
                </div>
                {/* <div className = "teamDiv">
                    <div className = "teamPlayer">
                        <LazyLoadImage className = "teamPic" src = {Maicoo} alt = "Dr. Maicoo in CitiDental Portmore Office"/>
                        <p className = "teamLabel">Dr. Aaron Maicoo, D.D.S.</p>
                    </div>
                    <p className = "teamPara">
                        Dr. Maicoo, hailing from the picturesque village of Fyzabad in Trinidad, was raised in a family woven 
                        with diverse talents. His mother's prominence as a Christmas singer and educator, alongside his father's 
                        expertise in chemical engineering, laid the foundation for his upbringing. Completing his education at 
                        Iere High School, Dr. Maicoo pursued his passion for dentistry at the University of the West Indies Dental 
                        School, graduating in 2019.
                        <br/><br/><br/>
                        With over four years of dental experience, Dr. Maicoo excels in performing intricate root canal procedures, 
                        prioritizing patient comfort and precision. Beyond dentistry, his passions span cooking, guitar playing, and 
                        an avid interest in Formula 1 racing, cricket, and simulated racing with the Jamaica Sim Racing Association.
                        Dr. Maicoo embodies a dedicated dental practitioner, combining technical proficiency with empathy and a diverse 
                        array of interests, enriching both his professional and personal life.
                    </p>
                </div> */}
            </div>
        </div>
    );
}
