import React, { useRef } from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import contMobile414 from '../files/contMobile414.webp';
import contMobile360 from '../files/contMobile360.webp';
import contMobile390 from '../files/contMobile390.webp';
import contMobile601 from '../files/contMobile601.webp';
import contMobile768 from '../files/contMobile768.webp';
import contMobile800 from '../files/contMobile800.webp';
import contMobile810 from '../files/contMobile810.webp';
import contMobile820 from '../files/contMobile820.webp';
import citiTeam1792 from '../files/citiTeam1792.webp';
import citiTeam1180 from '../files/citiTeam1180.webp';
import citiTeam1280 from '../files/citiTeam1280.webp';
import citiTeam1366 from '../files/citiTeam1366.webp';
import citiTeam1440 from '../files/citiTeam1440.webp';
import citiTeam1536 from '../files/citiTeam1536.webp';
import citiTeam1600 from '../files/citiTeam1600.webp';
import citiTeam1920 from '../files/citiTeam1920.webp';
import contactCiti360 from '../files/contactCiti360.webp';
import contactCiti390 from '../files/contactCiti390.webp';
import contactCiti414 from '../files/contactCiti414.webp';
import contactCiti601 from '../files/contactCiti601.webp';
import contactCiti768 from '../files/contactCiti768.webp';
import contactCiti800 from '../files/contactCiti800.webp';
import contactCiti810 from '../files/contactCiti810.webp';
import contactCiti820 from '../files/contactCiti820.webp';
import contactCiti1180 from '../files/contactCiti1180.webp';
import contactCiti1280 from '../files/contactCiti1280.webp';
import contactCiti1366 from '../files/contactCiti1366.webp';
import contactCiti1440 from '../files/contactCiti1440.webp';
import contactCiti1536 from '../files/contactCiti1536.webp';
import contactCiti1600 from '../files/contactCiti1600.webp';
import contactCiti1792 from '../files/contactCiti1792.webp';
import contactCiti1920 from '../files/contactCiti1920.webp';
import { Helmet } from 'react-helmet';
import emailjs from '@emailjs/browser';

export default function Contact() {
    const form = useRef();
    const [conChangeSub, setChange] = React.useState("contactForm")
    const [contactChanger, setChanger] = React.useState("contactSection")
    function handleSubmit(event) {
        event.preventDefault()
        emailjs.sendForm('service_ilf18wb', 'template_ey11gy7', form.current, '5J_MWT3yVlyTMFHqS')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
        const sent = document.getElementById("sentConfirm")
        setTimeout(() => {
            setChange("conDisplay")
            setChanger("contactSection2")
          }, 500);
        setTimeout(() => {
            sent.innerText = "Your message has been sent successfully"
        }, 500);
    }

    return (
        <div>
            <Helmet>
                <title>Contact Us | CitiDental & Implant Centre</title>
                <meta
                name="description"
                content="Need to reach us? You can call us at (876) 612-7921, email us at support@citidentalja.com, or send us a message through our online web form or social media."
                />
            </Helmet>
            <div className = 'pictureDivText'>
                <img 
                    className = "darkenCover fullPhoto mobileOnly"
                    src={contMobile601} 
                    srcSet={`${contMobile360} 360px, ${contMobile390} 390px, ${contMobile414} 414px, 
                            ${contMobile601} 601px, ${contMobile768} 768px, ${contMobile800} 800px, 
                            ${contMobile810} 810px, ${contMobile820} 820px`}
                    sizes="(max-width: 360px) 360px, (max-width: 390px) 390px, (max-width: 414px) 414px,
                        (max-width: 601px) 601px, (max-width: 768px) 768px, (max-width: 800px) 800px,
                        (max-width: 810px) 810px, (max-width: 820px) 820px, 100vw"
                    alt = "CitiDental's Doctors and Staff"
                />
                <img 
                    className = "darkenCover fullPhoto desktopOnly" 
                    src={citiTeam1366} 
                    srcSet={`${citiTeam1180} 1180px, ${citiTeam1280} 1280px, ${citiTeam1366} 1366px, 
                            ${citiTeam1440} 1440px, ${citiTeam1536} 1536px, ${citiTeam1600} 1600px, 
                            ${citiTeam1792} 1792px, ${citiTeam1920} 1920px`}
                    sizes="(max-width: 1180px) 1180px, (max-width: 1280px) 1280px, (max-width: 1366px) 1366px,
                        (max-width: 1440px) 1440px, (max-width: 1536px) 1536px, (max-width: 1600px) 1600px,
                        (max-width: 1792px) 1792px, (max-width: 1920px) 1920px, 100vw"
                    alt = "CitiDental's Doctors and Staff"
                />
                <h1 className = 'pictureHeader' id = "contFont">Contact CitiDental</h1>
                <p className = "pictureText onlyCenter">
                    Opening Hours
                    <br/><br/>
                    Tuesday 9am - 5pm, Wednesday & Thursday 9am - 7pm, Friday 9am - 2pm, Saturday 8am - 7pm
                </p>
            </div>
            <div className = {contactChanger}>
                <form className = {conChangeSub} id = "conSub" onSubmit = {handleSubmit} autoComplete = "on" ref={form}>
                    <input 
                        type = "text" 
                        placeholder = "First Name" 
                        aria-label = "Enter your First Name"
                        className = "inputField"
                        name = "first_name"
                        autoComplete="given-name"
                        required
                    />
                    <input 
                        type = "text" 
                        placeholder = "Last Name" 
                        aria-label = "Enter your Last Name"
                        className = "inputField"
                        name = "last_name"
                        autoComplete="family-name"
                        required
                    />
                    <input 
                        type = "text" 
                        placeholder = "Phone Number" 
                        aria-label = "Enter your Phone Number"
                        className = "inputField"
                        name = "phone"
                        autoComplete = "tel"
                    />
                    <input 
                        type = "text" 
                        placeholder = "Email" 
                        aria-label = "Enter your Email"
                        className = "inputField"
                        name = "email"
                        autoComplete = "email"
                        required
                    />
                    <input 
                        type = "text" 
                        placeholder = "Subject" 
                        aria-label = "Enter the subject of your message"
                        className = "inputField"
                        name = "subject"
                        autoComplete = "off"
                        required
                    />
                    <textarea 
                        type = "text" 
                        placeholder = "What would you like to tell us?" 
                        aria-label = "Enter the message you would like to tell us."
                        className = "inputField"
                        id = "textbox"
                        name = "message"
                        autoComplete = "off"
                        required
                    ></textarea>
                    <button type = "submit" className = "contactSubmit">Send Message</button>
                </form>
                <div className = "contactRight">
                    <h3 className = "contacth3 onlyCenter">We look forward to brightening your smile!</h3>
                    <LazyLoadImage 
                        src = {contactCiti820}
                        srcSet={`${contactCiti360} 360px, ${contactCiti390} 390px, ${contactCiti414} 414px, 
                                ${contactCiti601} 601px, ${contactCiti768} 768px, ${contactCiti800} 800px, 
                                ${contactCiti810} 810px, ${contactCiti820} 820px, ${contactCiti1180} 1180px, 
                                ${contactCiti1280} 1280px, ${contactCiti1366} 1366px, 
                                ${contactCiti1440} 1440px, ${contactCiti1536} 1536px, ${contactCiti1600} 1600px, 
                                ${contactCiti1792} 1792px, ${contactCiti1920} 1920px`}
                        sizes="(max-width: 360px) 360px, (max-width: 390px) 390px, (max-width: 414px) 414px,
                                (max-width: 601px) 601px, (max-width: 768px) 768px, (max-width: 800px) 800px,
                                (max-width: 810px) 810px, (max-width: 820px) 820px, (max-width: 1180px) 1180px,
                                (max-width: 1280px) 1280px, (max-width: 1366px) 1366px,
                                (max-width: 1440px) 1440px, (max-width: 1536px) 1536px, (max-width: 1600px) 1600px,
                                (max-width: 1792px) 1792px, (max-width: 1920px) 1920px, 414px" 
                        id = "contactImg" 
                        alt = "CitiDental's Excellent Customer Care"
                    />
                    <p id = "sentConfirm"> </p>
                </div>
            </div>
        </div>
        
    );
}