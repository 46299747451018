import React from 'react';
import happyPatient360 from '../files/happyPatient360.webp';
import happyPatient390 from '../files/happyPatient390.webp';
import happyPatient414 from '../files/happyPatient414.webp';
import happyPatient601 from '../files/happyPatient601.webp';
import happyPatient768 from '../files/happyPatient768.webp';
import happyPatient800 from '../files/happyPatient800.webp';
import happyPatient810 from '../files/happyPatient810.webp';
import happyPatient820 from '../files/happyPatient820.webp';
import happyPatient1180 from '../files/happyPatient1180.webp';
import happyPatient1280 from '../files/happyPatient1280.webp';
import happyPatient1366 from '../files/happyPatient1366.webp';
import happyPatient1440 from '../files/happyPatient1440.webp';
import happyPatient1536 from '../files/happyPatient1536.webp';
import happyPatient1600 from '../files/happyPatient1600.webp';
import happyPatient1792 from '../files/happyPatient1792.webp';
import happyPatient1920 from '../files/happyPatient1920.webp';
import mooreDaughter360 from '../files/mooreDaughter360.webp';
import mooreDaughter390 from '../files/mooreDaughter390.webp';
import mooreDaughter414 from '../files/mooreDaughter414.webp';
import mooreDaughter601 from '../files/mooreDaughter601.webp';
import mooreDaughter768 from '../files/mooreDaughter768.webp';
import mooreDaughter800 from '../files/mooreDaughter800.webp';
import mooreDaughter810 from '../files/mooreDaughter810.webp';
import mooreDaughter820 from '../files/mooreDaughter820.webp';
import mooreDaughter1180 from '../files/mooreDaughter1180.webp';
import mooreDaughter1280 from '../files/mooreDaughter1280.webp';
import mooreDaughter1366 from '../files/mooreDaughter1366.webp';
import mooreDaughter1440 from '../files/mooreDaughter1440.webp';
import mooreDaughter1536 from '../files/mooreDaughter1536.webp';
import mooreDaughter1600 from '../files/mooreDaughter1600.webp';
import mooreDaughter1792 from '../files/mooreDaughter1792.webp';
import mooreDaughter1920 from '../files/mooreDaughter1920.webp';
import ligOffice360 from '../files/ligOffice360.webp';
import ligOffice390 from '../files/ligOffice390.webp';
import ligOffice414 from '../files/ligOffice414.webp';
import ligOffice601 from '../files/ligOffice601.webp';
import ligOffice768 from '../files/ligOffice768.webp';
import ligOffice800 from '../files/ligOffice800.webp';
import ligOffice810 from '../files/ligOffice810.webp';
import ligOffice820 from '../files/ligOffice820.webp';
import ligOffice1180 from '../files/ligOffice1180.webp';
import ligOffice1280 from '../files/ligOffice1280.webp';
import ligOffice1366 from '../files/ligOffice1366.webp';
import ligOffice1440 from '../files/ligOffice1440.webp';
import ligOffice1536 from '../files/ligOffice1536.webp';
import ligOffice1600 from '../files/ligOffice1600.webp';
import ligOffice1792 from '../files/ligOffice1792.webp';
import ligOffice1920 from '../files/ligOffice1920.webp';
import procedure360 from '../files/procedure360.webp';
import procedure390 from '../files/procedure390.webp';
import procedure414 from '../files/procedure414.webp';
import procedure601 from '../files/procedure601.webp';
import procedure768 from '../files/procedure768.webp';
import procedure800 from '../files/procedure800.webp';
import procedure810 from '../files/procedure810.webp';
import procedure820 from '../files/procedure820.webp';
import procedure1180 from '../files/procedure1180.webp';
import procedure1280 from '../files/procedure1280.webp';
import procedure1366 from '../files/procedure1366.webp';
import procedure1440 from '../files/procedure1440.webp';
import procedure1536 from '../files/procedure1536.webp';
import procedure1600 from '../files/procedure1600.webp';
import procedure1792 from '../files/procedure1792.webp';
import procedure1920 from '../files/procedure1920.webp';
import {FaMapMarkedAlt} from 'react-icons/fa';
import {AiFillClockCircle} from 'react-icons/ai';
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Visit() {
    return(
        <div id = "visitDiv">
            <h2 id = "visitHeadMob">Visit CitiDental</h2>
            <div id = "visitFeat">
                <div id = "visitPics">
                    <div>
                        <LazyLoadImage 
                            className = "fullWidth zoom" 
                            id = "grid1" 
                            src = {happyPatient820}
                            srcSet={`${happyPatient360} 360w, ${happyPatient390} 390w, ${happyPatient414} 414w, 
                                    ${happyPatient601} 601w, ${happyPatient768} 768w, ${happyPatient800} 800w, 
                                    ${happyPatient810} 810w, ${happyPatient820} 820w, ${happyPatient1180} 1180w, 
                                    ${happyPatient1280} 1280w, ${happyPatient1366} 1366w, 
                                    ${happyPatient1440} 1440w, ${happyPatient1536} 1536w, ${happyPatient1600} 1600w, 
                                    ${happyPatient1792} 1792w, ${happyPatient1920} 1920w`}
                            sizes="(max-width: 360px) 360px, (max-width: 390px) 390px, (max-width: 414px) 414px,
                                    (max-width: 601px) 601px, (max-width: 768px) 768px, (max-width: 800px) 800px,
                                    (max-width: 810px) 810px, (max-width: 820px) 820px, (max-width: 1180px) 1180px,
                                    (max-width: 1280px) 1280px, (max-width: 1366px) 1366px,
                                    (max-width: 1440px) 1440px, (max-width: 1536px) 1536px, (max-width: 1600px) 1600px,
                                    (max-width: 1792px) 1792px, (max-width: 1920px) 1920px, 601px"
                            alt = "Happy CitiDental Patients"/>
                    </div>
                    <div>
                        <LazyLoadImage 
                            className = "fullWidth zoom" 
                            id = "grid2" 
                            src = {mooreDaughter820}
                            srcSet={`${mooreDaughter360} 360w, ${mooreDaughter390} 390w, ${mooreDaughter414} 414w, 
                                    ${mooreDaughter601} 601w, ${mooreDaughter768} 768w, ${mooreDaughter800} 800w, 
                                    ${mooreDaughter810} 810w, ${mooreDaughter820} 820w, ${mooreDaughter1180} 1180w, 
                                    ${mooreDaughter1280} 1280w, ${mooreDaughter1366} 1366w, 
                                    ${mooreDaughter1440} 1440w, ${mooreDaughter1536} 1536w, ${mooreDaughter1600} 1600w, 
                                    ${mooreDaughter1792} 1792w, ${mooreDaughter1920} 1920w`}
                            sizes="(max-width: 360px) 360px, (max-width: 390px) 390px, (max-width: 414px) 414px,
                                    (max-width: 601px) 601px, (max-width: 768px) 768px, (max-width: 800px) 800px,
                                    (max-width: 810px) 810px, (max-width: 820px) 820px, (max-width: 1180px) 1180px,
                                    (max-width: 1280px) 1280px, (max-width: 1366px) 1366px,
                                    (max-width: 1440px) 1440px, (max-width: 1536px) 1536px, (max-width: 1600px) 1600px,
                                    (max-width: 1792px) 1792px, (max-width: 1920px) 1920px, 601px" 
                            alt = "Dr. Moore and her Daughter"
                        />
                    </div>
                    <div>
                        <LazyLoadImage 
                            className = "fullWidth zoom" 
                            id = "grid3" 
                            src = {procedure820}
                            srcSet={`${procedure360} 360w, ${procedure390} 390w, ${procedure414} 414w, 
                                    ${procedure601} 601w, ${procedure768} 768w, ${procedure800} 800w, 
                                    ${procedure810} 810w, ${procedure820} 820w, ${procedure1180} 1180w, 
                                    ${procedure1280} 1280w, ${procedure1366} 1366w, 
                                    ${procedure1440} 1440w, ${procedure1536} 1536w, ${procedure1600} 1600w, 
                                    ${procedure1792} 1792w, ${procedure1920} 1920w`}
                            sizes="(max-width: 360px) 360px, (max-width: 390px) 390px, (max-width: 414px) 414px,
                                    (max-width: 601px) 601px, (max-width: 768px) 768px, (max-width: 800px) 800px,
                                    (max-width: 810px) 810px, (max-width: 820px) 820px, (max-width: 1180px) 1180px,
                                    (max-width: 1280px) 1280px, (max-width: 1366px) 1366px,
                                    (max-width: 1440px) 1440px, (max-width: 1536px) 1536px, (max-width: 1600px) 1600px,
                                    (max-width: 1792px) 1792px, (max-width: 1920px) 1920px, 601px" 
                            alt = "CitiDental Procedure in progress"
                        />
                    </div>
                    <div>
                        <LazyLoadImage 
                            className = "fullWidth zoom" 
                            id = "grid4" 
                            src = {ligOffice820}
                            srcSet={`${ligOffice360} 360w, ${ligOffice390} 390w, ${ligOffice414} 414w, 
                                    ${ligOffice601} 601w, ${ligOffice768} 768w, ${ligOffice800} 800w, 
                                    ${ligOffice810} 810w, ${ligOffice820} 820w, ${ligOffice1180} 1180w, 
                                    ${ligOffice1280} 1280w, ${ligOffice1366} 1366w, 
                                    ${ligOffice1440} 1440w, ${ligOffice1536} 1536w, ${ligOffice1600} 1600w, 
                                    ${ligOffice1792} 1792w, ${ligOffice1920} 1920w`}
                            sizes="(max-width: 360px) 360px, (max-width: 390px) 390px, (max-width: 414px) 414px,
                                    (max-width: 601px) 601px, (max-width: 768px) 768px, (max-width: 800px) 800px,
                                    (max-width: 810px) 810px, (max-width: 820px) 820px, (max-width: 1180px) 1180px,
                                    (max-width: 1280px) 1280px, (max-width: 1366px) 1366px,
                                    (max-width: 1440px) 1440px, (max-width: 1536px) 1536px, (max-width: 1600px) 1600px,
                                    (max-width: 1792px) 1792px, (max-width: 1920px) 1920px, 601px"
                            alt = "CitiDental Liguanea Office"
                        />
                    </div>
                </div>
                <div id = "visitInfo">
                    <h2 id = "visitHead">Visit CitiDental</h2>
                    <div>
                        <p className = "darkWhiteText" style = {{paddingBottom: 40 + 'px'}}>
                            We are located upstairs in Sovereign Village, Portmore and on 
                            the second floor in Progressive Plaza, Liguanea.
                        </p>
                        <div className = "darkWhiteText is-Flex-Col">
                            <div>
                                <p>
                                    <span className = "paddingRight20"><FaMapMarkedAlt /></span>
                                    <a className = "mapLink" href = "https://tinyurl.com/CitidentalPortmore" target="_blank" rel="noopener noreferrer">
                                        Sovereign Village, Portmore
                                    </a>
                                    <span className = "paddingLeft10">
                                        <a className = "mapLinkButton" href = "https://tinyurl.com/CitidentalPortmore" target="_blank" rel="noopener noreferrer">
                                            Get Directions
                                        </a>
                                    </span>
                                </p>
                            </div>
                            <div>
                                <p>
                                    <span className = "paddingRight20"><FaMapMarkedAlt /></span>
                                    <a className = "mapLink" href = "https://tinyurl.com/CitidentalLiguanea" rel="noopener noreferrer" target="_blank">
                                        Progressive Plaza, Liguanea, 24-28 Barbican Rd
                                    </a>
                                    <span className = "paddingLeft10">
                                        <a className = "mapLinkButton" href = "https://tinyurl.com/CitidentalLiguanea" rel="noopener noreferrer" target="_blank">
                                            Get Directions
                                        </a>
                                    </span>
                                </p>
                            </div>
                            <div>
                                <p>
                                    <span className = "paddingRight20"><AiFillClockCircle /></span>
                                    Opening Hours
                                </p>
                                <table id = "mobileCenterTable">
                                    <tbody>
                                        <tr>
                                            <td>Monday</td>
                                            <td>Closed</td>
                                        </tr>
                                        <tr>
                                            <td>Tuesday</td>
                                            <td>9am - 5pm</td>
                                        </tr>
                                        <tr>
                                            <td>Wednesday</td>
                                            <td>9am - 7pm</td>
                                        </tr>
                                        <tr>
                                            <td>Thursday</td>
                                            <td>9am - 7pm</td>
                                        </tr>
                                        <tr>
                                            <td>Friday</td>
                                            <td>9am - 2pm</td>
                                        </tr>
                                        <tr>
                                            <td>Saturday</td>
                                            <td>8am - 7pm</td>
                                        </tr>
                                        <tr>
                                            <td>Sunday</td>
                                            <td>Closed</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}