import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function SliderSimple(props) {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000
    };
    return (
        <Slider {...settings}>
            {props.packages.map((packageItem, i) => (
                <div key = {i} className = {props.over}>
                    <div className={props.divClass}>
                        <p className={props.textClass}>{packageItem}</p>
                    </div>
                    <hr />
                    <div>
                        <p className={props.authClass}>{props.names[i]}</p>
                    </div>
                </div>
            ))}
        </Slider>
    );
};
