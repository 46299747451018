import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ServBox from './ServicesBox.js';

export default function ServicesSlider(props) {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        autoplay: true,
        autoplaySpeed: 15000
    };
    return (
        <Slider {...settings}>
            {props.info.map((information, i) => (
                <div key = {i} className = {props.divClass}>
                    <ServBox 
                        info = {information}
                        title = {props.title[i]}
                        img = {props.sources[i]}
                        alt = {props.alt[i]}
                    />
                </div>
            ))}
        </Slider>
    );
};
