import React from 'react';

export default function BookOnlineSect() {
    return (
        <div id = "onlineBook">
            <h2 className = "bookHead">Book your visit Online</h2>
            <p id = "bookSectPara">
                View availble appointments and enjoy our state of the art, world class dental office.
                <br className = "noMobile"/> With free condiments, unparalleled customer service and 
                most importantly exemplary dental care.
            </p>
            <div id = "bookSectDiv">
                <a style = {{padding: 20 + 'px'}} className = "bookNow" href = "https://tinyurl.com/bookCitidental">Book Online Now</a>
            </div>
        </div>
    );
}