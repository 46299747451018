import React from 'react';
import meds from '../files/meds.webp';
import ServBox from '../functions/ServicesBox.js';
import CompCleaning from '../files/CompCleaning.webp';
import WisdomTeeth from '../files/Wisdom Teeth.webp';
import Extraction from '../files/Extraction.webp';
import Whitening from '../files/teethWhitening copy.webp';
import Braces from '../files/braces copy.webp';
import Filling from '../files/compfilling.webp';
import Implants from '../files/implants copy.webp';
import Veneers from '../files/veneers.webp';
import Crown from '../files/crown copy.webp';
import Bridges from '../files/bridges.webp';
import Gums from '../files/gums.webp';
import Dentures from '../files/servdentures.webp';
import RootCanal from '../files/Root Canal.webp';
import Sedation from '../files/Sedation.webp';
import MobileServices from '../files/mobileServices.webp';
import Slider from '../functions/ServicesSlider.js';
import { Helmet } from 'react-helmet';

export default function Services() {
    return (
        <div>
            <Helmet>
                <title>Full-Service Dental Office | CitiDental & Implant Centre</title>
                <meta
                name="description"
                content="From implants and crowns to root canals and extractions we do it all. We have an experienced dental team that will give post-treatment tips and are kid-friendly"
                />
            </Helmet>
            <div className = 'pictureDivText'>
                <img 
                    className = "darkenCover fullPhoto desktopOnly" 
                    src = {meds} 
                    alt = "CitiDental's Doctors and Staff"
                    fetchpriority="high"
                />
                <img 
                    className = "darkenCover fullPhoto mobileOnly" 
                    src = {MobileServices} 
                    alt = "CitiDental's Doctors and Staff"
                    fetchpriority="high"
                />
                <h1 className = 'pictureHeader'>Our Services</h1>
            </div>
            <div className = "servicesPre">
                <div>
                    <h2 id = "servicesh2" className='mobilePad'>CitiDental & Implant Centre is a full-service dental office!</h2>
                    <p className = "servicesPara">
                        We are a one-stop-shop for all your dental needs. Whether it be implants, 
                        root canals, braces, you name it. If you are unsure of what you need done
                        you can easily schedule
                        a <a 
                            href = "https://app.nexhealth.com/appt/citidental"
                            target = "is_blank"
                            className = "hyperLink"
                            referrer = "nonreferrer noopener">
                                consultation online
                        </a> and book an appointment at 
                        your convenience. Read on for more information about the procedures we offer
                        (don't worry, we'll take good care of you :))
                    </p>
                </div>
                <div>
                    <h3 className = "servicesH3">General Dentistry</h3>
                    <p className = "servicesPara">
                        We are dedicated to providing comprehensive general dental services 
                        to our valued patients. With a commitment to excellence and a team of skilled 
                        professionals, we offer a wide range of services to address all your dental needs.
                    </p>
                    <div className = "generalDent desktopOnly">
                        <div className='servContainer'>
                            <ServBox 
                                info = {`Regular dental cleanings are the cornerstone of good oral hygiene. 
                                        Our experienced dental hygienists employ advanced techniques and 
                                        state-of-the-art equipment to remove plaque and tartar buildup, 
                                        preventing cavities and gum disease.`}
                                title = "Comprehensive Cleanings"
                                img = {CompCleaning}
                                alt = "CitiDental's Comprehensive Cleaning for General Dental Care"
                            />
                        </div>
                        <div className='servContainer'>
                            <ServBox 
                                info = {`Wisdom teeth, also known as third molars, can often cause discomfort, 
                                        misalignment, and other dental issues. Our skilled oral surgeons 
                                        specialize in the safe and efficient removal of wisdom teeth.`}
                                title = "Wisdom Teeth Extraction"
                                img = {WisdomTeeth}
                                alt = "CitiDental's Wisdom Teeth Extraction for General Dental Care"
                            />
                        </div>
                        <div className='servContainer'>
                            <ServBox 
                                info = {`When a tooth is infected or severely damaged, a root canal 
                                        treatment can often save it from extraction. Our experienced 
                                        dentists employ precision techniques, anesthesia and cutting-edge 
                                        technology to remove the infected pulp, clean the root canals, 
                                        and seal the tooth.`}
                                title = "Root Canals"
                                img = {RootCanal}
                                alt = "CitiDental's Root Canals for General Dental Care"
                            />
                        </div>
                        <div className='servContainer'>
                            <ServBox 
                                info = {`While our primary goal is to preserve natural teeth, there are instances 
                                        where extraction becomes necessary. Whether due to severe decay, overcrowding, 
                                        or trauma, our experienced dentists at CitiDental are skilled in performing 
                                        extractions with precision and care.`}
                                title = "Extractions"
                                img = {Extraction}
                                alt = "CitiDental's Extractions for General Dental Care"
                            />
                        </div>
                        <div className='servContainer'>
                            <ServBox 
                                info = {`We understand that dental anxiety can be a significant barrier 
                                        to seeking essential care. Our experienced team will work 
                                        with you to determine the most appropriate level of sedation for your 
                                        specific needs, ensuring a calm and relaxed experience.`}
                                title = "Sedation Dentistry"
                                img = {Sedation}
                                alt = "CitiDental's Sedation Dentistry"
                            />
                        </div>
                    </div>
                    <div className = "mobileOnly servCarousel">
                        <Slider 
                            info = {[`Regular dental cleanings are the cornerstone of good oral hygiene. 
                                    Our experienced dental hygienists employ advanced techniques and 
                                    state-of-the-art equipment to remove plaque and tartar buildup, 
                                    preventing cavities and gum disease.`,

                                    `Wisdom teeth, also known as third molars, can often cause discomfort, 
                                    misalignment, and other dental issues. Our skilled oral surgeons 
                                    specialize in the safe and efficient removal of wisdom teeth.`,
                                
                                    `When a tooth is infected or severely damaged, a root canal 
                                    treatment can often save it from extraction. Our experienced 
                                    dentists employ precision techniques, anesthesia and cutting-edge 
                                    technology to remove the infected pulp, clean the root canals, 
                                    and seal the tooth.`,
                                
                                    `While our primary goal is to preserve natural teeth, there are instances 
                                    where extraction becomes necessary. Whether due to severe decay, overcrowding, 
                                    or trauma, our experienced dentists at CitiDental are skilled in performing 
                                    extractions with precision and care.`,
                                
                                    `We understand that dental anxiety can be a significant barrier 
                                    to seeking essential care. Our experienced team will work 
                                    with you to determine the most appropriate level of sedation for your 
                                    specific needs, ensuring a calm and relaxed experience.`]}
                            alt = {["CitiDental's Comprehensive Cleaning for General Dental Care",
                                    "CitiDental's Wisdom Teeth Extraction for General Dental Care",
                                    "CitiDental's Root Canals for General Dental Care", 
                                    "CitiDental's Extractions for General Dental Care", "CitiDental's Sedation Dentistry"]}
                            sources = {[CompCleaning, WisdomTeeth, RootCanal, Extraction, Sedation]}
                            title = {["Comprehensive Cleanings", "Wisdom Teeth Extraction", "Root Canals", "Extractions",
                                    "Sedation Dentistry"]}
                        />
                    </div>
                </div>
                <div>
                    <h3 className = "servicesH3">Cosmetic Dentistry</h3>
                    <p className = "servicesPara">
                        At CitiDental, we believe that a confident, radiant smile is a powerful asset. 
                        Our cosmetic dental services are designed to enhance the aesthetic 
                        appeal of your teeth and boost your self-confidence. 
                        <br className = 'mobileOnly'/><br className = 'mobileOnly'/>
                        With a team of skilled 
                        professionals and cutting-edge technology, we offer a range of treatments tailored 
                        to meet your unique needs. From veneers to implants, our cosmetic dentistry services 
                        are dedicated to giving you a smile that you'll be proud to share with the world.
                    </p>
                    <div className = "generalDent desktopOnly" id = "cosmetic">
                        <div className='servContainer'>
                            <ServBox 
                                info = {`For those seeking a smile transformation, veneers are a popular 
                                        choice. These ultra-thin porcelain shells are custom-crafted to cover the 
                                        front surface of your teeth, correcting imperfections like stains, chips, 
                                        and misalignments. Veneers provide a natural-looking, long-lasting solution 
                                        for a flawless smile.`}
                                title = "Veneers"
                                img = {Veneers}
                                alt = "CitiDental's Veneers for Cosmetic Dental Care"
                            />
                        </div>
                        <div className='servContainer'>
                            <ServBox 
                                info = {`Brighten your smile and enhance your overall appearance with our professional 
                                        teeth whitening services. Using advanced techniques and high-quality materials, 
                                        our team can effectively remove stubborn stains and discolorations, leaving you 
                                        with a dazzling, youthful smile.`}
                                title = "Teeth Whitening"
                                img = {Whitening}
                                alt = "CitiDental's Teeth Whitening for Cosmetic Dental Care"
                            />
                        </div>
                        <div className='servContainer'>
                            <ServBox 
                                info = {`Achieving a straighter smile has never been easier with our orthodontic services. 
                                        We offer various types of braces, including traditional, ceramic, and Invisalign®. These 
                                        treatments align your teeth and can improve overall facial symmetry.`}
                                title = "Braces"
                                img = {Braces}
                                alt = "CitiDental's Braces for Cosmetic Dental Care"
                            />
                        </div>
                        <div className='servContainer'>
                            <ServBox 
                                info = {`Our tooth-colored fillings are a discreet and effective way to restore teeth 
                                        affected by cavities or minor damage. These composite resin fillings blend 
                                        seamlessly with your natural teeth, providing a durable and aesthetically 
                                        pleasing solution.`}
                                title = "Fillings"
                                img = {Filling}
                                alt = "CitiDental's Fillings for Cosmetic Dental Care"
                            />
                        </div>
                        <div className='servContainer'>
                            <ServBox 
                                info = {`If you're missing one or more teeth, dental implants offer a permanent and 
                                        natural-looking solution. Our skilled implant specialists use state-of-the-art 
                                        technology to securely anchor artificial teeth in your jawbone, restoring both 
                                        the aesthetics and functionality of your smile.`}
                                title = "Implants"
                                img = {Implants}
                                alt = "CitiDental's Implants for Cosmetic Dental Care"
                            />
                        </div>
                    </div>
                    <div className = "mobileOnly servCarousel">
                        <Slider 
                            info = {[`For those seeking a smile transformation, veneers are a popular 
                                    choice. These ultra-thin porcelain shells are custom-crafted to cover the 
                                    front surface of your teeth, correcting imperfections like stains, chips, 
                                    and misalignments. Veneers provide a natural-looking, long-lasting solution 
                                    for a flawless smile.`,
                                
                                    `Brighten your smile and enhance your overall appearance with our professional 
                                    teeth whitening services. Using advanced techniques and high-quality materials, 
                                    our team can effectively remove stubborn stains and discolorations, leaving you 
                                    with a dazzling, youthful smile.`,
                                
                                    `Achieving a straighter smile has never been easier with our orthodontic services. 
                                    We offer various types of braces, including traditional, ceramic, and Invisalign®. These 
                                    treatments align your teeth and can improve overall facial symmetry.`,
                                
                                    `Our tooth-colored fillings are a discreet and effective way to restore teeth 
                                    affected by cavities or minor damage. These composite resin fillings blend 
                                    seamlessly with your natural teeth, providing a durable and aesthetically 
                                    pleasing solution.`,
                                
                                    `If you're missing one or more teeth, dental implants offer a permanent and 
                                    natural-looking solution. Our skilled implant specialists use state-of-the-art 
                                    technology to securely anchor artificial teeth in your jawbone, restoring both 
                                    the aesthetics and functionality of your smile.`]}

                            title = {["Veneers", "Teeth Whitening", "Braces", "Fillings", "Implants"]}

                            sources = {[Veneers, Whitening, Braces, Filling, Implants]}

                            alt = {["CitiDental's Veneers for Cosmetic Dental Care",
                                    "CitiDental's Teeth Whitening for Cosmetic Dental Care",
                                    "CitiDental's Braces for Cosmetic Dental Care",
                                    "CitiDental's Fillings for Cosmetic Dental Care",
                                    "CitiDental's Implants for Cosmetic Dental Care"]}
                        />
                    </div>
                </div>
                <div>
                    <h3 className = "servicesH3">Restorative Dentistry</h3>
                    <p className = "servicesPara">
                        At CitiDental, we understand that maintaining a healthy, functional smile is 
                        essential for overall well-being. Our restorative dentistry services are 
                        meticulously crafted to repair and rejuvenate your teeth and gums. 
                        <br className = 'mobileOnly'/><br className = 'mobileOnly'/>
                        With a 
                        team of skilled professionals and advanced technology, we offer a range of 
                        treatments tailored to restore your oral health and confidence. From crowns 
                        to gum surgeries, our restorative dentistry services are dedicated to bringing 
                        back the natural beauty and functionality of your smile.
                    </p>
                    <div className = "desktopOnly" id = "restDent">
                        <div className='servContainer'>
                            <ServBox 
                                info = {`Our custom-crafted dental crowns are a versatile solution for damaged 
                                        or weakened teeth. Whether due to decay, fractures, or other issues, 
                                        crowns provide a durable and aesthetically pleasing way to protect and 
                                        strengthen your natural teeth. With our high-quality materials 
                                        your smile will look and feel as good as new.`}
                                title = "Crowns"
                                img = {Crown}
                                alt = "CitiDental's Crowns for Restorative Dental Care"
                            />
                        </div>
                        <div className='servContainer'>
                            <ServBox 
                                info = {`For individuals with missing teeth, dental bridges offer a reliable 
                                        and aesthetically pleasing option. These custom-made prosthetics 
                                        consist of artificial teeth anchored to adjacent healthy teeth or 
                                        implants.`}
                                title = "Bridges"
                                img = {Bridges}
                                alt = "CitiDental's Bridges for Restorative Dental Care"
                            />
                        </div>
                        <div className='servContainer'>
                            <ServBox 
                                info = {`Our gum treatments encompass a range of procedures aimed at 
                                        preserving and restoring gum health. This includes deep cleanings 
                                        to remove plaque and tartar, as well as 
                                        advanced gum surgeries for cases requiring more extensive intervention.`}
                                title = "Gum Treatments"
                                img = {Gums}
                                alt = "CitiDental's Gum Treatments for Restorative Dental Care"
                            />
                        </div>
                        <div className='servContainer'>
                            <ServBox 
                                info = {`For those with multiple missing teeth, our custom-fitted dentures provide 
                                        a comfortable and functional solution. We offer both partial and complete 
                                        dentures to meet your specific needs. Our denture specialists ensure a 
                                        comfortable fit, allowing you to eat, speak, and smile with confidence.`}
                                title = "Dentures"
                                img = {Dentures}
                                alt = "CitiDental's Dentures for Restorative Dental Care"
                            />
                        </div>
                    </div>
                    <div className = "mobileOnly servCarousel">
                        <Slider 
                            info = {[`Our custom-crafted dental crowns are a versatile solution for damaged 
                            or weakened teeth. Whether due to decay, fractures, or other issues, 
                            crowns provide a durable and aesthetically pleasing way to protect and 
                            strengthen your natural teeth. With our high-quality materials 
                            your smile will look and feel as good as new.`,
                        
                            `For individuals with missing teeth, dental bridges offer a reliable 
                            and aesthetically pleasing option. These custom-made prosthetics 
                            consist of artificial teeth anchored to adjacent healthy teeth or 
                            implants.`,
                        
                            `Our gum treatments encompass a range of procedures aimed at 
                            preserving and restoring gum health. This includes deep cleanings 
                            to remove plaque and tartar, as well as 
                            advanced gum surgeries for cases requiring more extensive intervention.`,
                        
                            `For those with multiple missing teeth, our custom-fitted dentures provide 
                            a comfortable and functional solution. We offer both partial and complete 
                            dentures to meet your specific needs. Our denture specialists ensure a 
                            comfortable fit, allowing you to eat, speak, and smile with confidence.`]}

                            title = {["Crowns", "Bridges", "Gum Treatments", "Dentures"]}

                            sources = {[Crown, Bridges, Gums, Dentures]}

                            alt = {["CitiDental's Crowns for Restorative Dental Care",
                                    "CitiDental's Bridges for Restorative Dental Care",
                                    "CitiDental's Gum Treatments for Restorative Dental Care",
                                    "CitiDental's Dentures for Restorative Dental Care"]}
                        />
                    </div>
                </div>
            </div>
            <div className = "onlyCenter" id = "servBook">
                <a className = "bookNow" id = "servBookButton" href = "https://tinyurl.com/bookCitidental">Book Your Appointment Today!</a>
            </div>
        </div>
    );
}
