import React from 'react';
import { Helmet } from 'react-helmet';
import smile360 from '../files/smile360.webp';
import smile390 from '../files/smile390.webp';
import smile414 from '../files/smile414.webp';
import smile601 from '../files/smile601.webp';
import smile768 from '../files/smile768.webp';
import smile800 from '../files/smile800.webp';
import smile810 from '../files/smile810.webp';
import smile820 from '../files/smile820.webp';
import smile1180 from '../files/smile1180.webp';
import smile1280 from '../files/smile1280.webp';
import smile1366 from '../files/smile1366.webp';
import smile1440 from '../files/smile1440.webp';
import smile1536 from '../files/smile1536.webp';
import smile1600 from '../files/smile1600.webp';
import smile1792 from '../files/smile1792.webp';
import smile1920 from '../files/smile1920.webp';
import Instafeed from 'instafeed.js'

export default function Smiles() {
    React.useEffect(() => {
        const userFeed = new Instafeed({
            get: 'user',
            target: 'instafeed-container',
            resolution: 'standard_resolution',
            accessToken: process.env.REACT_APP_INSTAGRAM_KEY});
        userFeed.run();
    }, []);
    return (
        <div className = 'pictureDivText'>
            <Helmet>
                <title>Our Jamaican Smiles | CitiDental & Implant Centre</title>
                <meta
                name="description"
                content="Featuring the beautiful Jamaican smiles we have had the pleasure of cleaning and treating. Want to be featured? Come visit! Located in Portmore and Liguanea."
                />
            </Helmet>
            <img 
                className = "darkenCover fullPhoto" 
                src = {smile1366} 
                srcSet={`${smile360} 360w, ${smile390} 390w, ${smile414} 414w, 
                        ${smile601} 601w, ${smile768} 768w, ${smile800} 800w, 
                        ${smile810} 810w, ${smile820} 820w, ${smile1180} 1180w, 
                        ${smile1280} 1280w, ${smile1366} 1366w, 
                        ${smile1440} 1440w, ${smile1536} 1536w, ${smile1600} 1600w, 
                        ${smile1792} 1792w, ${smile1920} 1920w`}
                sizes="(max-width: 360px) 360px, (max-width: 390px) 390px, (max-width: 414px) 414px,
                        (max-width: 601px) 601px, (max-width: 768px) 768px, (max-width: 800px) 800px,
                        (max-width: 810px) 810px, (max-width: 820px) 820px, (max-width: 1180px) 1180px,
                        (max-width: 1280px) 1280px, (max-width: 1366px) 1366px,
                        (max-width: 1440px) 1440px, (max-width: 1536px) 1536px, (max-width: 1600px) 1600px,
                        (max-width: 1792px) 1792px, (max-width: 1920px) 1920px, 100vw" 
                alt = "CitiDental's Smile Page"
            />
            <h1 id = "smileHeader">The Citidental Family</h1>
            <div id="instafeed-container"></div>
            <Helmet>
                <script src="https://cdn.jsdelivr.net/gh/stevenschobert/instafeed.js@2.0.0rc1/src/instafeed.min.js" />
            </Helmet>
        </div>
    );
}
