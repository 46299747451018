import { LazyLoadImage } from "react-lazy-load-image-component";

export default function ServicesBox(props) {
    return(
        <div className = "servBox zoomDesk">
            <LazyLoadImage className = "servPic" src = {props.img} alt = {props.alt} />
            <div className = "servBoxText">
                <h3><span id = "headBorder">{props.title}</span></h3>
                <p className = "servicesPara">{props.info}</p>
            </div>
        </div>
    )
}
