import React from 'react';
import Header from './header.js';
import Footer from './footer.js';
import Announcements from './announcements.js';
import BookOnlineSect from './bookOnlineSect.js';
import {Outlet} from 'react-router-dom';
import Visit from './visitUs.js'

export default function Layout() {
    return (
        <div>
            <div>
                <a id = "permBook" href = "https://tinyurl.com/bookCitidental">Book Now</a>
            </div>
            <Announcements />
            <Header />
            <main>
                <Outlet />
            </main>
            <Visit />
            <BookOnlineSect />
            <Footer />
        </div>
    );
}