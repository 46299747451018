import React from 'react';
import braces from '../files/braces.webp';
import filling from '../files/toothFilling.webp';
import extraction from '../files/toothExtraction.webp';
import whitening from '../files/teethWhitening.webp';
import dentures from '../files/dentures.webp';
import root from '../files/root.webp';
import crown from '../files/crown.webp';
import implants from '../files/implants.webp';
import oral from '../files/oral surgery.webp';
import cleaning from '../files/deepCleaning.webp';
import bracesDesk from '../files/bracesDesk.webp';
import toothFillingDesk from '../files/toothFillingDesk.webp';
import toothExtractionDesk from '../files/toothExtractionDesk.webp';
import teethWhiteningDesk from '../files/teethWhiteningDesk.webp';
import denturesDesk from '../files/denturesDesk.webp';
import rootDesk from '../files/rootDesk.webp';
import crownDesk from '../files/crownDesk.webp';
import implantsDesk from '../files/implantsDesk.webp';
import oralDesk from '../files/oral surgeryDesk.webp';
import deepCleaningDesk from '../files/deepCleaningDesk.webp';
import { LazyLoadImage } from "react-lazy-load-image-component";
import {NavLink} from 'react-router-dom';
import Slider from '../functions/Reel.js';

export default function ServicesReel(props) {
    return(
        <div className = 'serviceH'>
            <div className = "onlyCenter" id = {props.id}>
                <h2 className = "servMobH2" id = {props.h2}>{props.title}</h2>
                <div id = "slider" style = {{paddingBottom: 40 + "px"}}>
                    <Slider 
                        divClass = "carouselDiv"
                        link = "/services"
                        picClass = "onlyCenter servPic"
                        images = {[implants, oral, dentures, whitening, crown, root, braces, extraction, cleaning, filling]}
                        alt = {["CitiDental Implants", "CitiDental Oral Surgery", "CitiDental Dentures", "CitiDental Whitening",
                                "CitiDental Crowns and Bridges", "CitiDental Root Canals", "CitiDental Braces",
                                "CitiDental Extractions and Fillings", "CitiDental Cleanings", "CitiDental Cosmetic Dentistry"]}
                        headText = {["Implants", "Oral Surgery", "Dentures", "Teeth Whitening", "Crowns and Bridges", "Root Canals",
                                    "Braces", "Extractions and Fillings", "Cleanings", "Cosmetic Dentistry"]}
                        headClass = "mobh3"
                        headId = "headBorder"
                    />
                </div>
                <div id = {props.id2}>
                    <div className = "servGrid zoom">
                        <LazyLoadImage className = "servPic" src = {implantsDesk} alt = "CitiDental Implants" />
                        <h3 className = "h3Serve"><span id = "headBorder">Implants</span></h3>
                        <p className = "paraServ">When it comes to dental implants, Citidental 
                            has you covered. One of our top procedures. <span className = "embolden underline">
                            <NavLink title = "Citidental's Dental Treatment Services"
                                className = "hyperLink" 
                                rel = "noopener nonreferrer" 
                                to = "/services">Read more
                            </NavLink></span>
                        </p>
                    </div>
                    <div className = "servGrid zoom">
                        <LazyLoadImage className = "servPic" src = {oralDesk} alt = "CitiDental Oral Surgery" />
                        <h3 className = "h3Serve"><span id = "headBorder">Oral Surgery</span></h3>
                        <p className = "paraServ">At Citidental, patients can rest assured that they are in the 
                            capable hands of expert oral surgeons. <span className = "embolden underline">
                            <NavLink title = "Citidental's Dental Treatment Services"
                            className = "hyperLink" rel = "noopener nonreferrer" to = "/services">Read more</NavLink></span>
                        </p>
                    </div>
                    <div className = "servGrid zoom">
                        <LazyLoadImage className = "servPic" src = {denturesDesk} alt = "CitiDental Dentures" />
                        <h3 className = "h3Serve"><span id = "headBorder">Dentures</span></h3>
                        <p className = "paraServ">We offer dentures customized to provide you with comfortable
                            and natural-looking solutions for restoring smiles. <span className = "embolden underline">
                            <NavLink title = "Citidental's Dental Treatment Services" 
                            className = "hyperLink" rel = "noopener nonreferrer" to = "/services">Read more</NavLink></span>
                        </p>
                    </div>
                    <div className = "servGrid zoom">
                        <LazyLoadImage className = "servPic" src = {teethWhiteningDesk} alt = "CitiDental Whitening" />
                        <h3 className = "h3Serve"><span id = "headBorder">Teeth Whitening</span></h3>
                        <p className = "paraServ">With our professional teeth whitening services, we help
                            patients achieve a whiter and more radiant set of teeth. <span className = "embolden underline">
                            <NavLink title = "Citidental's Dental Treatment Services"
                            className = "hyperLink" rel = "noopener nonreferrer" to = "/services">Read more</NavLink></span>
                        </p>
                    </div>
                    <div className = "servGrid zoom">
                        <LazyLoadImage className = "servPic" src = {crownDesk} alt = "CitiDental Crowns and Bridges" />
                        <h3 className = "h3Serve"><span id = "headBorder">Crowns and Bridges</span></h3>
                        <p className = "paraServ">We specialize in crafting durable and aesthetic crowns and bridges
                            that restore damaged teeth. <span className = "embolden underline">
                            <NavLink title = "Citidental's Dental Treatment Services"
                            className = "hyperLink" rel = "noopener nonreferrer" to = "/services">Read more</NavLink></span>
                        </p>
                    </div>
                    <div className = "servGrid zoom">
                        <LazyLoadImage className = "servPic" src = {rootDesk} alt = "CitiDental Root Canals" />
                        <h3 className = "h3Serve"><span id = "headBorder">Root Canals</span></h3>
                        <p className = "paraServ">We excel in performing root canals with precision and care, saving 
                            infected teeth and relieving pain. <span className = "embolden underline">
                            <NavLink title = "Citidental's Dental Treatment Services"
                            className = "hyperLink" rel = "noopener nonreferrer" to = "/services">Read more</NavLink></span>
                        </p>
                    </div>
                    <div className = "servGrid zoom">
                        <LazyLoadImage className = "servPic" src = {bracesDesk} alt = "CitiDental Braces" />
                        <h3 className = "h3Serve"><span id = "headBorder">Braces</span></h3>
                        <p className = "paraServ">With our orthodontic treatment, we help patients 
                            achieve straighter, more aligned smiles. <span className = "embolden underline">
                            <NavLink title = "Citidental's Dental Treatment Services"
                            className = "hyperLink" rel = "noopener nonreferrer" to = "/services">Read more</NavLink></span>
                        </p>
                    </div>
                    <div className = "servGrid zoom">
                        <LazyLoadImage className = "servPic" src = {toothExtractionDesk} alt = "CitiDental Extractions and Fillings" />
                        <h3 className = "h3Serve"><span id = "headBorder">Extractions and Fillings</span></h3>
                        <p className = "paraServ">Offering expert extractions and fillings to address tooth removal and 
                            restoration needs. <span className = "embolden underline">
                            <NavLink title = "Citidental's Dental Treatment Services"
                            className = "hyperLink" rel = "noopener nonreferrer" to = "/services">Read more</NavLink></span>
                        </p>
                    </div>
                    <div className = "servGrid zoom">
                        <LazyLoadImage className = "servPic" src = {deepCleaningDesk} alt = "CitiDental Cleanings" />
                        <h3 className = "h3Serve"><span id = "headBorder">Cleanings</span></h3>
                        <p className = "paraServ">Providing comprehensive dental cleanings that effectively remove plaque
                            and tartar buildup. <span className = "embolden underline">
                            <NavLink title = "Citidental's Dental Treatment Services"
                            className = "hyperLink" rel = "noopener nonreferrer" to = "/services">Read more</NavLink></span>
                        </p>
                    </div>
                    <div className = "servGrid zoom">
                        <LazyLoadImage className = "servPic" src = {toothFillingDesk} alt = "CitiDental Cosmetic Dentistry" />
                        <h3 className = "h3Serve"><span id = "headBorder">Cosmetic Dentistry</span></h3>
                        <p className = "paraServ">We transform smiles and boost patients' confidence by offering 
                            personalized treatments and procedures. <span className = "embolden underline">
                            <NavLink title = "Citidental's Dental Treatment Services"
                            className = "hyperLink" rel = "noopener nonreferrer" to = "/services">Read more</NavLink></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}